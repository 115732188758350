<template>
  <v-dialog :value="true" scrollable width="920" persistent>
    <v-card
      ><v-system-bar window>
        Dispensationen erfassen<v-spacer /><v-btn icon @click="close()"
          ><v-icon> >mdi-close</v-icon></v-btn
        ></v-system-bar
      >
      <v-card-text class="pa-0">
        <v-stepper v-model="step" flat>
          <v-stepper-header class="elevation-0">
            <v-stepper-step :complete="step > 1" step="1">
              Erfassen
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 2" step="2">
              Überprüfen
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3"> Speichern </v-stepper-step>
          </v-stepper-header>
          <v-divider />
          <v-progress-linear
            :color="progress == null ? 'white' : 'primary'"
            :value="progress"
          />

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col>
                    <PersonInput
                      v-model="candidate"
                      group="student"
                      clearable
                      label="Schüler:in hinzufügen"
                      @input="add(candidate)"
                    />
                  </v-col>
                  <v-col cols="auto">
                    <v-switch inset v-model="item.physicalEducation">
                      <template v-slot:label
                        ><v-icon left>mdi-run</v-icon> Sportdispensation
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
                <v-row class="my-0">
                  <v-chip
                    v-for="item in students"
                    :key="'s-' + item.id"
                    @click:close="remove(item)"
                    class="ma-1"
                    close
                  >
                    <v-avatar left>
                      <PortraitImage :value="item" />
                    </v-avatar>
                    <PersonName :value="item" />
                  </v-chip>
                </v-row>
                <v-row class="my-0">
                  <v-col>
                    <v-text-field label="Grund" v-model="item.description" />
                  </v-col>
                </v-row>
                <v-row class="my-0">
                  <v-col cols="auto">
                    <v-switch inset v-model="allDay">
                      <template v-slot:label> ganztägig </template>
                      <template v-slot:append>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on"
                              >mdi-help-circle-outline</v-icon
                            >
                          </template>
                          <span
                            >Die Dispensation gilt von morgens früh bis abends
                            spät!</span
                          >
                        </v-tooltip>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col>
                    <v-row class="mb-0">
                      <v-col>
                        <DateInput
                          v-model="item.startDate"
                          label="von Tag"
                          required
                        />
                      </v-col>
                      <v-col>
                        <TimeInput
                          :disabled="allDay"
                          :label="allDay ? 'morgens' : 'ab'"
                          v-model="item.startTime"
                          :required="!allDay"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="mt-0">
                      <v-col>
                        <DateInput
                          v-model="item.endDate"
                          label="bis Tag"
                          required
                        />
                      </v-col>
                      <v-col>
                        <TimeInput
                          :disabled="allDay"
                          :label="allDay ? 'abends' : 'bis'"
                          v-model="item.endTime"
                          :required="!allDay"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="my-0">
                  <v-col>
                    <v-switch inset v-model="showDayOfWeek">
                      <template v-slot:label>
                        auf Wochentag beschränken
                      </template>
                      <template v-slot:append>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on"
                              >mdi-help-circle-outline</v-icon
                            >
                          </template>
                          <span
                            >Die Dispensation gilt für alle ausgewählten
                            Wochentage im Zeitraum «von Tag» bis «bis Tag» in
                            den gewählten Lektionen!</span
                          >
                        </v-tooltip>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col
                    ><DayOfWeekInput
                      :disabled="!showDayOfWeek"
                      v-model="dayOfWeek"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="2">
              <template v-if="absences.length > 0">
                <v-alert type="error" border="left" text prominent>
                  Es gibt Konflikte mit den folgenden Absenzen. Diese müssen
                  zuerst manuell gelöscht werden!
                </v-alert>
                <v-card outlined>
                  <v-system-bar>Konflikte (Absenzen)</v-system-bar>
                  <AbsenceList :items="absences" @updated="checkAbsences" />
                </v-card>
              </template>
              <template v-else>
                <v-alert
                  type="success"
                  border="left"
                  text
                  tile
                  prominent
                  icon="mdi-thumb-up"
                >
                  Es gibt keine Konflikte mit vorhandenen Absenzen.
                </v-alert>
                <v-alert type="info" text prominent border="left">
                  Die folgenden Dispensationen
                  {{ saveable ? "können" : "sollen" }} erzeugt werden:
                  <v-list light>
                    <DispensationItem
                      v-for="(dispensation, index) in items"
                      :key="`dispensation${index}`"
                      :value="dispensation"
                    />
                  </v-list>
                </v-alert>
              </template>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-alert type="info" text tile prominent icon="mdi-floppy">
                Die folgenden Dispensationen wurden erfolgreich erzeugt:
                <v-list light>
                  <DispensationItem
                    v-for="(dispensation, index) in itemsSuccess"
                    :key="`itemsSuccess${index}`"
                    :value="dispensation"
                  />
                </v-list>
              </v-alert>
              <v-alert
                type="error"
                text
                tile
                prominent
                v-if="itemsError.length > 0"
              >
                Die folgenden Dispensationen konnten nicht erzeugt werden!
                <v-list light>
                  <template v-for="(dispensation, index) in itemsError">
                    <DispensationItem
                      :key="`itemsError${index}`"
                      :value="dispensation"
                    />
                    <v-list-item :key="`itemsErrorMsg${index}`">{{
                      item.msg
                    }}</v-list-item>
                  </template>
                </v-list>
              </v-alert>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>

      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn v-if="step == 2" @click="step = 1" text>zurück</v-btn>
        <v-btn v-if="step == 1" @click="close()" text>abbrechen</v-btn>
        <v-btn
          @click="checkAbsences()"
          text
          color="primary"
          v-if="step == 1"
          :disabled="!this.checkable"
          >überprüfen</v-btn
        >
        <v-btn
          @click="saveDispensation()"
          :loading="saving"
          text
          color="primary"
          v-if="step == 2"
          :disabled="!this.saveable"
          >speichern</v-btn
        >
        <v-btn v-if="step == 3" @click="close()" text>Schliessen</v-btn>
      </v-card-actions></v-card
    ></v-dialog
  >
</template>
<script>
import DispensationItem from "common/components/DispensationItem.vue";
import DateInput from "common/components/DateInput.vue";
import DayOfWeekInput from "@/components/DayOfWeekInput.vue";
import PersonInput from "common/components/PersonInput.vue";
import PersonName from "common/components/PersonName.vue";
import PortraitImage from "common/components/PortraitImage.vue";
import TimeInput from "common/components/TimeInput.vue";

import AbsenceList from "../Absences/AbsenceList.vue";

import { addDays } from "common/utils/date.js";
import { personName } from "common/utils/people.js";
export default {
  components: {
    AbsenceList,
    DateInput,
    DayOfWeekInput,
    DispensationItem,
    PersonInput,
    PersonName,
    PortraitImage,
    TimeInput,
  },
  props: ["term", "schoolClass"],
  data() {
    return {
      absences: [],
      allDay: true,
      candidate: {},
      dayOfWeek: null,
      students: [],
      showDayOfWeek: false,
      step: 1,
      progress: null,
      item: {
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
        physicalEducation: false,
      },

      itemsError: [],
      itemsSuccess: [],
      checking: false,
      noAbsences: false,
      saving: false,
      valid: false,
    };
  },
  computed: {
    items() {
      if (!this.checkable) {
        return;
      }
      if (!this.dayOfWeek) {
        const result = [];
        for (const student of this.students) {
          result.push({ ...this.item, student: student });
        }
        return result;
      }
      const dispensations = [];
      let day = new Date(this.item.startDate);

      while (day.getDay() !== this.dayOfWeek.id) {
        day = new Date(addDays(day, 1));
      }
      const endDate = new Date(this.item.endDate);
      while (day <= endDate) {
        dispensations.push({
          startDate: day.toISOString().split("T")[0],
          endDate: day.toISOString().split("T")[0],
          startTime: this.item.startTime,
          endTime: this.item.endTime,
          physicalEducation: this.item.physicalEducation,
          description: this.item.description,
        });
        day = new Date(addDays(day, 7));
      }
      const result = [];
      for (const student of this.students) {
        for (const dispensation of dispensations) {
          result.push({ ...dispensation, student: student });
        }
      }
      return result;
    },
    checkable() {
      return (
        this.students.length > 0 &&
        this.item.startDate &&
        this.item.endDate &&
        (!this.showDayOfWeek || this.dayOfWeek)
      );
    },
    saveable() {
      return this.valid && this.checkable && this.noAbsences;
    },
  },
  watch: {
    allDay() {
      if (this.allDay) {
        this.item.startTime = null;
        this.item.endTime = null;
      }
    },
    showDayOfWeek() {
      if (!this.showDayOfWeek) {
        this.dayOfWeek = null;
      }
    },
  },
  methods: {
    personName,
    add(candidate) {
      if (candidate && !this.students.includes(candidate)) {
        this.students.push(candidate);
      }
      this.candidate = {};
    },
    close() {
      this.$router.push({ name: "Dispensations" });
    },
    remove(candidate) {
      const index = this.students.indexOf(candidate);
      if (index >= 0) {
        this.students.splice(index, 1);
      }
    },
    async checkAbsences() {
      this.checking = true;
      this.noAbsences = false;
      this.step = 2;
      this.absences = [];
      const step = 100 / (2 * this.items.length);
      this.progress = 0;
      for await (const item of this.items) {
        const absences = await this.apiList({
          resource: "absence/absence",
          query: `startDate=${item.startDate}&startTime=${
            item.startTime
          }&endDate=${item.endDate}&endTime=${item.endTime}&student=${
            item.student.id
          }${item.physicalEducation ? "&physicalEducation=true" : ""}`,
        });
        this.progress = this.progress + step;
        this.absences.push(...absences);
      }
      this.progress = null;

      this.noAbsences = this.absences.length == 0;
      this.checking = false;
    },
    async saveDispensation() {
      this.saving = true;
      this.itemsError = [];
      this.itemsSuccess = [];
      this.step = 3;
      const step = 100 / this.items.length;
      this.progress = 0;
      for await (const item of this.items) {
        await this.apiPost({
          resource: "absence/dispensation",
          data: item,
          onError: (msg) => {
            this.itemsError.push({ ...item, msg: msg });
          },
          onSuccess: () => {
            this.itemsSuccess.push(item);
          },
        });
        this.progress = this.progress + step;
      }
      setTimeout(() => {
        this.saving = false;
        if (this.itemsError.length == 0) {
          this.$root.showSuccess(
            "alle Dispensationen wurden erfolgreich erzeugt"
          );
          this.$emit("update");
        }
      }, 1500);
    },
  },
};
</script>
<style scoped>
:deep(.v-stepper__wrapper) {
  overflow-x: visible;
  overflow-y: visible;
}
</style>
